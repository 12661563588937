.local-nav {
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;

  ul {
    margin: 0;
    padding: 0;
    display: inline-block;

    li {
      list-style: none;
      display: inline-block;
      margin-right: 2rem;
      padding-bottom: 0.75rem;
      color: #666;
      font-weight: bold;
      font-size: 1rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .is-active {
      color: #333;
      padding-bottom: 0.7rem;
      border-bottom: 3px solid $color-primary;
    }
  }
}
