.page-login {
    display: grid;
    height: 100vh;
    width: 100%;

  .left {
    margin: auto 2rem;
  }

  input {
    margin-bottom: 0;
  }

  button {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .page-login {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .left {
      margin: auto 7.5rem;
    }

    .right {
      background-color: $color-primary;

      div {
        text-align: center;
        padding: 48vh 0;

        span {
          color: #fff;
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
  }
}
