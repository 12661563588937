// Variables
$color-primary: #7ca870;
$color-white: #fff;
$color-border: #ddd;
$color-grey--light: rgb(245, 245, 245);
$color-text: #333;
$color-alert: #ffe600;
$color-warning: #ea3c53;
$color-success: #7ca870;
$color-cream: #f4f6f3;

$breakpoint-mobile: 576px;
$breakpoint-mobile-portrait: 768px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 992px;
$breakpoint-desktop: 1200px;

// Components
@import './components/button';
@import './components/card';
@import './components/history';
@import './components/income-stats';
@import './components/input';
@import './components/label';
@import './components/loading';
@import './components/login';
@import './components/menu-details';
@import './components/menus';
@import './components/modal';
@import './components/nav-local';
@import './components/nav';
@import './components/page';
@import './components/product';
@import './components/products';
@import './components/skeleton';
@import './components/table';
@import './components/users';

// Util
@import './util/display';
@import './util/grid';
@import './util/margin';
@import './util/padding';
@import './util/typography';
