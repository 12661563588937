button,
.button {
  color: $color-white;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  height: 40px;

  &:hover {
    background-color: $color-white;
    border: 1px solid $color-primary;
    color: $color-primary;
  }
}

.button {
  &-secondary {
    background-color: $color-white;
    color: $color-primary;
    border: 1px solid $color-primary;

    &:hover {
      background-color: $color-primary;
      border: 1px solid $color-primary;
      color: $color-white;
    }
  }

  &-warning {
    background-color: $color-warning;
    color: $color-white;
    border: 1px solid $color-warning;

    &:hover {
      background-color: $color-white;
      border: 1px solid $color-warning;
      color: $color-warning;
    }
  }

  &--only-text {
    background: none;
    border: none;
    color: #333;
    padding: 0;
    height: 1rem;

    svg {
      margin-right: 5px;
    }

    &:hover {
      border: none;
    }
  }
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.buttons-two {
  button,
  .button {
    width: calc(50% - 2.5px) !important;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.button-with-dropdown {
  position: relative;

  &-group {
    border: 1px solid #cccccc;
    border-radius: 5px;

    span {
      padding: 10px;
    }

    button {
      background: none;
      border: none;
      border-left: 1px solid #ccc;
      border-radius: 0;
      color: #999;
      padding: 5px 12px;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    border: 1px solid #cccccc;
    border-radius: 5px;
    position: absolute;
    z-index: 2;
    min-width: 150px;

    button {
      background: #fff;
      border: none;
      border-radius: 5px;
      color: #666;
      padding: 5px 12px;
      text-align: left;
      width: 100%;

      &:hover {
        background-color: rgb(238, 238, 238);
        color: #333;
      }
    }
  }
}
