.card {
  width: 100%;
  border: 1px solid $color-border;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;

  &--medium {
    width: auto;
    padding: 10px;
    min-height: 190px;
  }

  &-title {
    font-size: 18px;
    font-weight: bold;

    &-area {
      min-height: 70px;
    }
  }

  &-description {
    display: block;
    font-style: italic;
  }

  &-extra {
    &-label {
      font-weight: bold;
    }

    &-value {
      float: right;
      color: $color-primary;
    }
  }

  &-action {
    margin: 5px;

    button {
      width: 100%;
    }
  }

  &-body {
    margin: 10px;

    &-section {
      margin-top: 10px;

      &-title {
        font-weight: bold;
        text-transform: capitalize;
      }

      ul {
        margin: 0;
        list-style-type: none;
        padding-left: 10px;
      }
    }
  }

  &-heading {
    border-bottom: 1px solid $color-border;
    background-color: $color-grey--light;

    .card-title {
      margin: 10px;
      font-size: 18px;
      display: inline-block;
    }

    .card-timer {
      float: right;
      position: relative;
      top: 12px;
      margin-right: 10px;
    }

    .card-menu {
      float: right;
      position: relative;
      top: 12px;
      margin-right: 10px;
      cursor: pointer;

      .actions {
        position: absolute;
        right: -10px;
        margin-top: 11px;
        width: 110px;
        text-align: center;
        padding: 10px 0;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;

        &::before {
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #fff;
          content: "";
          position: absolute;
          right: 5px;
          top: -7px;
        }

        .btn-action {
          cursor: pointer;
          padding: 5px 10px;

          &:hover {
            background: $color-grey--light;
            color: $color-primary;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .card {
    width: 300px;
    max-width: 300px;
    margin-right: 10px;
  }
}
