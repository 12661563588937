.sales {
    &__filters {
        &__wrapper {
            .products-search-wrapper {
                display: block;
                width: 100%;
            }

            .sales__filters__right {
                display: flex;

                .button-with-dropdown {
                    &-body {
                        background: #fff;
                    }

                    &:first-child {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

.event-sales-expander__items-header {
    display: flex;
    justify-content: space-between;

    svg {
        color: #7ca870;
        margin-right: 5px;
    }

    button {
        color: #7ca870;
        font-size: .9rem;
        line-height: 1rem;
        margin: auto 0;
        margin-right: 1rem;
    }
}

@media (min-width: 600px) {
    .sales {
        &__filters {
            &__wrapper {
                display: flex;
                justify-content: space-between;

                .products-search-wrapper {
                    display: block;
                    width: 50%;
                }

                .sales__filters__right {
                    position: relative;
                    top: 10px;
                    left: 2rem;
                }
            }
        }
    }
}