h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: $color-primary;
}

h2 {
  font-size: 1.5rem;
}

label {
  display: block;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: $color-primary;

  &:hover {
    text-decoration: underline;
  }
}

.p--info {
  padding: 10px 5px 10px 15px;
  background-color: #f5f8fa;
  border-left: 5px solid $color-alert;
}

.p--error {
  padding: 10px 5px 10px 15px;
  background-color: #f5f8fa;
  border-left: 5px solid $color-warning;
}

.logo {
  font-family: "Baloo Bhaina 2", cursive;
  color: #333 !important;
  text-decoration: none !important;
  font-size: 2rem !important;
  line-height: 2rem;

  &--large {
    font-size: 3rem !important;
    line-height: 3rem;
  }
}

.primary {
  color: $color-primary;
}

.bold {
  font-weight: 600;
}

.text-left {
  text-align: left;
}

.white-space {
  &--break-spaces {
    white-space: break-spaces;
  }
}
