.product-ticket__card {
  .card-common__wrapper {
    height: 100%;
  }

  .card-common__body__subtitle, .card-common__body__header {
    display: none !important;
  }

  .card-common__body__hr {
    margin: 1rem 0;
    height: 0;
  }

  .card-common__body__title {
    margin-top: 0;
  }

  &__size__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    .size-name {
      min-width: 70%;
      max-width: 70%;
    }
  }

  &--new {
    .card-common__body__subtitle {
      display: none !important;
    }
  }
}
