.padding {
    &-bottom {
      padding-bottom: 1rem !important;
  
      &--none {
        padding-bottom: 0rem !important;
      }
  
      &--half {
        padding-bottom: 0.5rem !important;
      }
  
      &--2 {
        padding-bottom: 2rem !important;
      }
    }
  
    &-right {
      padding-right: 1rem !important;
  
      &--none {
        padding-right: 0rem !important;
      }
  
      &--half {
        padding-right: 0.5rem !important;
      }
  
      &--2 {
        padding-right: 2rem !important;
      }
    }
  
    &-top {
      padding-top: 1rem !important;
  
      &--none {
        padding-top: 0rem !important;
      }
  
      &--half {
        padding-top: 0.5rem !important;
      }
  
      &--2 {
        padding-top: 2rem !important;
      }
    }
  
    &-left {
      padding-left: 1rem !important;
  
      &--none {
        padding-left: 0rem !important;
      }
  
      &--half {
        padding-left: 0.5rem !important;
      }
  
      &--2 {
        padding-left: 2rem !important;
      }
    }
  }