div[role=columnheader] {
  font-size: 1rem;
}

div[role=heading] {
  display: none;
}

.table-filters-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .filters-wrapper {
    display: flex;
    flex-direction: row;

    .filter-wrapper {
      width: 200px;
      margin-right: 1rem;
    }
    
  }
}
