.qr-page {
  .qr-table {
    .rdt_TableHead {
      display: none;
    }

    .rdt_TableCell {
      padding: 0;
      margin: 0;
    }

    .rdt_TableRow {
      border: none;
    }

    .qr-row {
      padding: 1rem;
      border: 1px solid #ddd;
      border-radius: 10px;
      width: 100%;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;

      .area {
        // TODO - these are hardcoded for now
        &--bar {
          background: #e6e9c0;
          color: #705816;
        }

        &--restaurant {
          background: #c0e4e9;
          color: #165370;
        }

        &-wrapper {
          margin: auto 0;
        }
      }

      button,
      .button {
        background: none;
        color: #7ca870;
        border: none;
        padding: 7px 12px;

        &--red {
          svg {
            color: #ea3c53;
          }
        }

        &:hover {
          background: #f4f6f3;
        }
      }

      .skeleton {
        background: #d8d8d8;
        background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
        background-repeat: no-repeat;
        background-size: 800px 104px;
      }
    }
  }

  .rdt_Pagination {
    border-top: none;
  }
}

.p--info {
  border-color: #008cff !important;
}

.number-label-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .switch-wrapper {
    margin-top: 1rem;

    span {
      display: inline-block;
      min-width: 60px;
      margin-left: 10px;
      position: relative;
      bottom: 5px;
    }
  }
}

.multiple-numbers-wrapper {
  display: flex;

  input {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.modal-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;

  .header-svg {
    background-color: #7ca870;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    svg {
      color: #fff;
      font-size: 2rem;
      position: relative;
      top: 8px;
    }
  }

  .location {
    margin-top: 1rem;

    .branch {
      display: block;
      font-size: 1.75rem;
      font-weight: 600;
      color: #7ca870;
    }

    .table {
      text-transform: capitalize;
    }
  }

  .qr-code {
    margin-top: 1rem;

    .qr-id-letter {
      font-weight: 600;
      padding: 5px;
      font-size: 1.25rem;
    }
  }
}
