.event-sales__payment-line {
    display: flex;
    justify-content: space-between;

    &__rhs {
        display: flex;
    }

    &__invoice {
        margin-left: 5px;
    }
}