.product {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 1rem;

  .img-wrapper {
    display: inline-block;
  }

  .details-wrapper {
    padding: 1rem;
    min-height: 130px;
    max-height: 130px;

    .title {
      display: block;
      font-size: 1.25rem;
      font-weight: bold;
    }

    .sub-title {
      max-height: 85px;
      overflow: auto;
    }

    .price {
      font-style: italic;
      color: $color-primary;
    }
  }

  .action {
    padding: 1rem;

    button {
      width: 100%;
    }

    .stock {
      display: inline-block;
    }

    .availability {
      &-wrapper {
        margin-bottom: 1rem;

        .availability-switch {
          float: right;

          span {
            display: inline-block;
            margin-left: 10px;
            position: relative;
            bottom: 7px;
            font-weight: bold;
            min-width: 95px;
            text-align: right;
          }

          .is-active {
            color: $color-primary;
          }
        }
      }
    }
  }
}

.products-search-wrapper {
  display: block;

  input {
    width: 90%;
  }

  button {
    float: right;
    position: relative;
    top: 12px;
  }
}

.products-sub-nav-wrapper {
  margin-bottom: 0.5rem;
}

#btn-new-product {
  display: block;
}

.new-product-form {
  width: 100%;

  label {
    margin-top: 1rem;
    margin-bottom: 5px;
  }

  button {
    display: block;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .product {
    display: inline-block;
    min-width: 100%;
    max-width: 100%;

    .img-wrapper,
    .details-wrapper {
      display: inline-block;
    }
  }

  .products-search-wrapper {
    display: inline-block;
    width: 50%;

    input {
      width: 330px;
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  #btn-new-product {
    float: right;
    position: relative;
    top: 15px;
  }

  .products-sub-nav-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
