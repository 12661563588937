.side-nav {
  $wrapper-padding: 1.25rem 2rem;

  background-color: #f4f6f3;
  min-height: 100vh;
  max-height: 100vh;
  width: 260px;
  z-index: 500;
  border-right: 1px solid #f4f7f4;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.5s;
  overflow: auto;

  .header {
    border-bottom: 1px solid $color-border;
    color: $color-primary;

    #logo,
    #notifications {
      display: inline-block;
      padding: 19px 2rem 10px 2rem;
      font-size: 1rem;
      font-weight: bold;
    }

    #notifications {
      color: #666;
      float: right;
    }
  }

  .person {
    padding: $wrapper-padding;
    padding-bottom: 0.5rem;

    .thumbnail {
      height: 60px;
      width: 60px;
      background-color: #fff;
      border-radius: 50%;
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 1px solid #e9ebe8;

      svg {
        color: #666;
        font-size: 1.5rem;
        position: relative;
        top: 1rem;
        left: 1.2rem;
      }
    }

    .name {
      display: block;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .location {
      color: $color-primary;
      font-weight: bold;

      &-pre {
        color: #666;
      }
    }
  }

  .nav-section {
    padding: $wrapper-padding;

    .title {
      display: block;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: bold;
      color: #666;
      margin-bottom: 0.5rem;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding-left: 1rem;

        a {
          display: block;
          width: 100%;
          padding: 0.75rem 0;
          margin: 0.25rem 0;
          text-decoration: none;
          color: #666;
        }

        svg {
          margin-right: 1rem;
          width: 24px;
        }
      }

      li:hover,
      .is-active {
        background-color: #fff;
        border-radius: 2rem;
        cursor: pointer;

        a {
          color: #333;
          font-weight: bold;
        }

        svg {
          color: $color-primary;
        }
      }
    }
  }
}

.nav-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  cursor: pointer;
}
