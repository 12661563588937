.skeleton {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #d8d8d8;
  background-image: linear-gradient(to right, #d8d8d8 0%, #bdbdbd 20%, #d8d8d8 40%, #d8d8d8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;

  &-button {
    &--icon-only {
      display: inline-block !important;
      width: 35px !important;
      height: 40px !important;
      margin-right: 10px !important;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-label {
    display: inline-block;
    width: 150px;
    height: 16px;
    padding: 0.2rem 0.75rem;
    border-radius: 22px;
  }
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
