.product-size-card {
  border: 1px solid $color-border;
  border-radius: 5px;
  text-align: center;
  padding: 1rem;

  .title,
  .description {
    display: block;
  }

  .title {
    font-size: 2rem !important;
  }

  .body {
    margin: 0;
    padding: 0;
    min-height: 175px;
    max-height: 175px;
  }

  .stock {
    border-radius: 5px 5px 0 0;
    padding: 0.75rem 1rem;
    text-align: left;
    margin-bottom: 2px;

    &-available {
      background-color: $color-primary;
      color: #fff;
    }

    &-unavailable {
      background-color: $color-alert;
      color: #333;
    }

    .toggle {
      float: right;
    }
  }

  .footer {
    background-color: #f4f6f3;
    border-radius: 0 0 5px 5px;
    padding: 1rem;

    .price {
      display: block;
      font-size: 2.25rem;
      margin-bottom: 1rem;
    }
  }
}

.product-size-card-new {
  border-style: dashed;
  border-color: $color-primary;

  input,
  textarea {
    margin-bottom: 1rem;
  }
}

.display_tag {
  background: #f4f6f3;
  border: 1px solid #f4f7f4;
  border-radius: 5px;
  color: #777;
  font-weight: 600;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 15px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  padding-left: 25px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);

  &--alert {
    background: #f4d9d7;
    color: #a6352c;
    box-shadow: 0 1px rgba(244, 217, 215, 0.5);
  }
}

.display_tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.recommended-size-wrapper {
  display: flex;
  justify-content: space-between;

  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;

  background-color: #eee;

  width: calc(100%-30px);
  margin-bottom: 1rem;

  &--selected {
    background-color: #fff;
  }

  .recommended-size-wrapper--select {
    display: flex;
    flex-direction: row;

    input {
      position: relative;
      top: 2px;
      margin-right: 10px;
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .recommended-size-wrapper {
    min-width: 500px;
    max-width: 500px;
  }

  .product-new-recommendations {
    margin-left: 3rem;
    width: 100%;

    .recommendation-subtitle {
      display: block;
      margin-top: 1rem;
      font-weight: 700;
    }
  }
}

.price-vat {
  font-size: 1rem;
}
