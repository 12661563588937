.hero {
  &__wrapper {
    overflow: hidden;
    flex: 0 0 auto;
    position: relative;
    background: #7ca870;
    color: #fff;

    svg {
      top: 0px !important;
      right: 0px !important;
      bottom: 0px !important;
      left: 0px !important;
      position: absolute;
      pointer-events: none;
      display: block;
      vertical-align: middle;
      --tw-border-opacity: 0.12;
      stroke: #80ad74;
      z-index: 5;
    }
  }

  &__content {
    z-index: 10;
    position: relative;
    text-align: center;
    padding: 2rem;
  }

  &__section {
    font-weight: 600;
    font-size: 1.125rem;
    --tw-border-opacity: 0.12;
    text-transform: uppercase;
  }

  &__title {
    font-size: 2rem;
    letter-spacing: -0.05rem;
    line-height: 1.25;
    font-weight: 800;
    margin-top: 0.25rem;
    --tw-border-opacity: 0.12;
    color: #fff;
  }

  &__subtitle {
    font-size: 1rem;
    margin-top: 1.5rem;
    color: rgb(235, 235, 235);
  }

  &__image {
    overflow: hidden;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &::after {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.6) 100%) repeat 0 0;
      z-index: 1;
    }
  }
}

@media (min-width: 600px) {
  .hero {
    &__wrapper {
    }

    &__content {
      padding: 4rem;
    }

    &__section {
    }

    &__title {
      font-size: 3rem;
    }

    &__subtitle {
      font-size: 1.25rem;
    }
  }
}
