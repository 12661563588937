@media only screen and (min-width: $breakpoint-tablet) {
  .history-page-wrapper {
    display: grid;
    grid-template-columns: 8fr 3fr;
    grid-column-gap: 5rem;
    grid-row-gap: 10px;
    width: 100%;
  }
}

.history-details-wrapper {
  background-color: #f4f6f3;
  min-height: 87vh;
  max-height: 87vh;
  padding: 2rem 2rem;
  margin: -2rem -5rem 0 0;
}

.history-table {
  .rdt_TableHeadRow {
    display: none;
  }

  .rdt_TableRow {
    border: none !important;
  }

  .rdt_TableCell {
    padding: 0;
    margin: 0;
  }

  .history-table-line {
    padding: 1rem;
    margin-top: 0.25rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:hover {
      background: #f4f6f3;
      cursor: pointer;
    }

    .order-details-wrapper {
      min-width: 20%;
      max-width: 20%;

      .label {
        display: inline-block;
      }
    }

    .customer-wrapper {
      margin: auto 0;
    }

    .destination {
      font-weight: 600;
      display: block;
      font-size: 1rem;
    }

    .order-id {
      text-transform: uppercase;
      color: #666;
    }

    .price {
      font-weight: 600;
      font-size: 1rem;
    }

    .height-wrapper {
      margin: auto 0;
    }

    .customer-name {
      font-size: 0.9rem;
      display: block;
      font-weight: 600;
    }
  }
}

.rdt_Pagination {
  border: none !important;

  > div > svg {
    position: absolute;
    top: 14px;
  }

  button {
    border: none;
    border-radius: 10px;
    margin-right: 5px;
    height: 30px;
    width: 30px;
    padding: 3px 2px;

    &:hover {
      background: $color-primary !important;

      svg {
        color: #fff;
        fill: #fff;
      }
    }

    &:disabled {
      &:hover {
        background: #ccc !important;

        svg {
          color: rgba(0, 0, 0, 0.18);
          fill: rgba(0, 0, 0, 0.18);
        }
      }
    }
  }
}

#pre-order-pdf-btn {
  position: relative;
  top: 19px;
}

.pre-order-switch-view-btn {
  background: none;
  border: none;
  color: #999;
  border-radius: 0;

  &:hover {
    background: none;
    border: none;
  }

  &--active, &--active:hover {
    color: $color-primary;
    background: $color-cream;
    cursor: default;
    border: 1px solid #e8e0c6;
    border-radius: 5px;
  }

  &-wrapper {
    display: inline-block;
    border: 1px solid #e8e0c6;
    position: relative;
    top: 19px;
    margin-bottom: 1rem;
    padding: 4px;
    border-radius: 5px;
  }
}

.pre-order-table-line {
  &-name, &-destination {
    flex-basis: 35%;
    min-width: 35% !important;
    max-width: 35% !important;
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .pre-order-table-line {
    &-name, &-destination, &-email {
      flex-basis: 20%;
      min-width: 20% !important;
      max-width: 20% !important;
    }
  
    &-date {
      flex-basis: 10%;
    }
  }
}

