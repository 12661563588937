.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  width: 100%;
}

.grid__1 {
  grid-template-columns: repeat(1, 1fr) !important;
}

@media only screen and (min-width: $breakpoint-tablet) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: $breakpoint-desktop) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .flex-grid {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--column-dir {
      flex-direction: column;
    }

    &--fullscreen {
      height: calc(100vh - 100px);
      max-height: calc(100vh - 100px);
    }
  }

  .col {
    flex: 0 0 auto;
  }
}
