.label {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 0px;
  padding: 0 0.75rem;
  border-radius: 22px;
  background: #eef3e8;
  color: #344d0f;
  font-weight: 600;
  text-transform: uppercase;
}

.alert {
  background-color: $color-alert;
  color: $color-text;
}

.warning {
  background: #f4d9d7;
  color: #a6352c;
}

.label--wip {
  display: inline-block;
}
