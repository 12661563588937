.ReactModal__Overlay {
  z-index: 999;
}

.ReactModal__Content {
  padding: 0 !important;
}

@media only screen and (min-width: $breakpoint-tablet) {
  .ReactModal__Content {
    margin: auto;
    max-width: 500px;
    max-height: 710px;
  }
}

.modal {
  &-title {
    display: block;
    font-size: 2rem;
    background-color: $color-primary;
    color: $color-white;
    padding: 1rem;
  }

  &-body {
    padding: 0 1rem;
  }

  &-close {
    display: block;
    float: right;
    font-size: 1rem;
    cursor: pointer;
  }

  &-fields {
    &-flex-columns-2 {
      display: flex;
      flex-direction: row;
    }

    label {
      width: 80%;
      display: inline-block;
      margin-top: 5px;
    }

    &-toggle {
      margin-top: 7px;
    }

    input {
      width: 80%;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 10px;
    }

    select {
      margin: 5px 0;
      background-color: $color-white;
      border-radius: 5px;
      border: 1px solid #ddd;
      padding: 10px;
      text-transform: capitalize;
    }
  }
}

// New dialogs
.dialogs--wrapper {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #999;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
}
