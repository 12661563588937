.event-details {
  &-local-nav,
  &-dates,
  &-description {
    margin: 0 auto;
  }

  &-warnings {
    margin: 0;
  }

  &__heading-with-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      padding: 0 10px;
      margin: 0;
      background: none;
      border: none;
      height: 1rem;

      svg {
        color: #333;
      }

      &:hover {
        svg {
          color: #7ca870;
        }
      }
    }
  }

  &__edit__image {
    min-width: 100%;
    max-width: 100%;

    &-upload__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input {
        margin: auto 0;
      }
    }
  }
}

.event-ticket__card {
  .card-common__wrapper {
    height: 100%;
  }

  .card-common__body__subtitle {
    display: none !important;
  }

  .card-common__body__hr {
    margin: 1rem 0;
    height: 0;
  }

  &__size__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    .size-name {
      min-width: 70%;
      max-width: 70%;
    }
  }
}

.events-page {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;

  .local-nav {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.event-sales-expander__wrapper {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 0.9rem;
  background: rgba(0, 0, 0, 0.03);

  label {
    margin-bottom: 2px;
  }

  input {
    min-width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    padding: 10px;
    margin-bottom: 5px;
  }

  textarea {
    min-width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }
}

.event-sales-expander__table {
  div[role="row"] {
    background: rgba(0, 0, 0, 0.03) !important;
    min-height: 2rem;
  }

  div[role="columnheader"] {
    font-size: 14px;
  }

  div[role="table"] {
    max-width: 600px;
  }
}

.event-sales-expander__invoice {
  display: block;
  margin-bottom: 0.5rem;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;

  svg {
    margin-right: 5px;
  }
}

.event-sales-actions__wrapper {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .event-sales-actions__wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .event-sales-actions-filter {
    min-width: 150px;
    max-width: 150px;
  }
}

@media only screen and (min-width: 992px) {
  .event-details {
    &-section__wrapper {
      min-width: 650px;
      max-width: 650px;
    }
  }

  .event-hero {
    .hero__subtitle {
      font-size: 1rem;
      max-width: 48rem;
      text-align: center;
      margin: 1.5rem auto 0;
    }
  }
}

.customer-info {
  h2 {
    display: flex;
    justify-content: space-between;

    button {
      padding: 0 7px;
      margin: 0;
      height: unset;
    }
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      svg {
        font-size: 0.8rem;
      }
    }

    &-icons {
      button {
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        height: 1rem;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }

        svg {
          color: #333;
        }

        &:hover {
          svg {
            color: #7ca870;
          }
        }
      }
    }
  }
}
