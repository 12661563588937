.user-letter-section-wrapper {
  .letter {
    font-weight: 700;
    color: $color-primary;
    border-bottom: 1px solid $color-border;
  }
}

.user {
  margin-bottom: 5px;
  padding: 1rem;
  border-radius: 10px;

  .img {
    display: inline-block;
    height: 32px;
    width: 32px;
    background-color: #ddd;
    margin-right: 1rem;
    border-radius: 50%;
  }

  .details {
    display: inline-block;

    .name {
      display: block;
      font-weight: 700;
    }

    .description {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $color-cream;
    cursor: pointer;
  }
}

@media only screen and (min-width: $breakpoint-desktop) {
  .letters-wrapper {
    overflow: auto;
    max-height: 75vh;
  }

  .users-page {
    display: flex;
    flex-direction: row;

    .body {
      padding: 1rem 2rem;
      // border-right: 1px solid $color-border;
      height: 90vh;
      width: 15%;
    }
  }
}

.user-profile-wrapper {
  background-color: $color-cream;
  padding: 1rem;
  height: 82vh;
  margin: 2rem;
  border-radius: 1rem;
  flex-grow: 1;
  width: 85%;
  align-items: center;
  text-align: center;
  position: relative;

  .actions-wrapper {
    position: absolute;
    right: 1rem;
    width: 25px;
    height: 25px;
    border-radius: 50%;

    svg {
      position: relative;
      top: 1px;
    }

    &:hover {
      cursor: pointer;
      background-color: #ddd;
      color: $color-primary;
    }
  }

  .actions {
    position: absolute;
    right: 15px;
    margin-top: 30px;
    width: 150px;
    text-align: center;
    padding: 10px 0;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;

    &::before {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #fff;
      content: "";
      position: absolute;
      right: 5px;
      top: -7px;
    }

    .btn-action {
      cursor: pointer;
      padding: 5px 10px;

      &:hover {
        background: $color-grey--light;
        color: $color-primary;
      }
    }
  }

  .user-img {
    height: 250px;
    width: 250px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #e9ebe8;
    margin: 0 auto;
    margin-top: 1rem;
    align-items: center;

    svg {
      margin: auto auto;
      display: block;
      height: 250px;
      font-size: 3rem;
    }
  }

  .user-name {
    display: block;
    margin-top: 2rem;
    font-size: 1.5rem;
    color: $color-primary;
    font-weight: 600;
  }

  .user-role {
    font-weight: 600;
    color: #666;
  }

  .user-calendar {
    margin-top: 2rem;
    padding: 0 3.5rem;

    &-header {
      display: flex;
      justify-content: space-between;

      svg {
        padding: 5px;
        border-radius: 50%;

        &:hover {
          cursor: pointer;
          background-color: #ddd;
          color: $color-primary;
        }
      }
    }

    .days-wrapper {
      text-align: left;

      .day {
        margin-top: 10px;

        .day-name {
          display: inline-block;
          min-width: 45px;
          max-width: 45px;
          font-weight: 600;
        }

        .working-hours {
          border-left: 3px solid $color-primary;
          margin-left: 10px;
          padding-left: 10px;

          &--none {
            border-left: 3px solid #999;
          }
        }
      }
    }
  }
}
