.menu-details-prods-section-title {
  background-color: $color-cream;
  padding: 5px 20px;
  border-radius: 7px;
  display: block;
  font-weight: 600;
}

.adding-to-menu-warning {
  display: flex;
  background-color: $color-warning;
  color: #fff;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: $color-primary;
    }
  }

  .cancel-add {
    position: relative;
    top: 1px;

    &:hover {
      color: $color-primary;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .menu-details-prods-section-title {
    width: 125px;
    display: inline-block;

    .menu-prod-card {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .adding-to-menu-warning {
    padding: 1rem;
  }
}

.menu-product-card {
  flex: 0 1 300px;
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 5px 10px;
  margin-right: 1rem;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 150px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  .prod-details {
    margin: 10px 0 5px 1rem;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .prod-warning {
      color: #fff;
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 0.9rem;
      background: $color-warning;
      padding: 2px 5px 5px 5px;
      border-radius: 5px;
    }

    .prod-category {
      border-left: 3px solid #f1b8a9;
      color: #f1b8a9;
      padding-left: 5px;
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 0.9rem;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .prod-name {
      font-weight: 600;
      margin-top: 5px;
    }

    .prod-action-wrapper {
      .prod-action-remove {
        color: $color-warning;
      }

      button {
        background: none;
        color: $color-primary;
        border: none;
        padding: 0;
        margin: 0;
        line-height: 1rem;
        margin-right: 1rem;

        &:hover {
          background: #f4f6f3;
        }

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .placeholder-img {
    display: inline-flex;
    width: 75px;
    height: 90%;
    margin: auto 5px;
    background-color: #e9f3f0;
    border-radius: 1rem;
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .menu-products-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .menu-product-card {
    flex: 0 1 calc(50% - 1rem);
  }
}

// TODO - this needs a tad more work
@media only screen and (min-width: 1600px) {
  .menu-product-card {
    flex: 0 1 calc(25% - 1rem);
  }
}

.menu-warning {
  display: inline-block;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 0.9rem;
  background: $color-warning;
  padding: 5px 7px 7px 7px;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.menu-filter {
  display: inline-block;
  color: #777;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 0.9rem;
  background: #f4f6f3;
  padding: 5px 7px 7px 7px;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 5px;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-left: 7px;
    position: relative;
    top: 1px;

    &:hover {
      color: $color-primary;
      cursor: pointer;
    }
  }
}

.time-wrapper {
  display: inline-block;
  width: 40%;
  margin-right: 1rem;

  label {
    font-weight: normal;
  }

  input {
    padding: 10px;
  }
}

.settings-card-wrapper {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0.75rem 1rem 1rem 1rem;

  .settings-card-header {
    display: flex;
    justify-content: space-between;

    span {
      line-height: 28px;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  p {
    color: #666;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
  }
}
