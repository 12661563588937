.margin {
  &-bottom {
    margin-bottom: 1rem;

    &--none {
      margin-bottom: 0rem;
    }

    &--half {
      margin-bottom: 0.5rem;
    }

    &--2 {
      margin-bottom: 2rem;
    }
  }

  &-right {
    margin-right: 1rem;

    &--none {
      margin-right: 0rem;
    }

    &--half {
      margin-right: 0.5rem;
    }

    &--2 {
      margin-right: 2rem;
    }
  }

  &-top {
    margin-top: 1rem;

    &--none {
      margin-top: 0rem;
    }

    &--half {
      margin-top: 0.5rem;
    }

    &--2 {
      margin-top: 2rem;
    }
  }

  &-left {
    margin-left: 1rem;

    &--none {
      margin-left: 0rem;
    }

    &--half {
      margin-left: 0.5rem;
    }

    &--2 {
      margin-left: 2rem;
    }
  }
}