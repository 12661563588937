.menus-page {
  max-width: 64rem;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-left: auto;
  margin-right: auto;

  .menus-sub-nav-wrapper {
    #btn-new-menu {
      display: inline-block;
    }
  }

  .menu-card-availability {
    &-wrapper {
      display: block;
    }

    padding: 0;
    margin: 0;
    margin-top: 0.5rem;

    li {
      list-style-type: none;
      display: inline-block;
      height: 25px;
      width: 25px;
      background-color: #bbb;
      border-radius: 50%;
      text-align: center;
      margin-right: 5px;

      &:last-child {
        margin-right: 0px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .is-available {
      background-color: $color-primary;

      span {
        color: #fff;
      }
    }
  }
}

.menu-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 1rem;

  .card-header {
    border-bottom: 1px solid #ddd;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;

    span {
      height: 25px;
    }

    .card-status {
      display: flex;
      align-items: center;

      .label {
        position: relative;
        top: -6px;
      }
    }
  }

  .card-body {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .availability {
      min-height: 55px;

      > span {
        font-weight: 600;
      }
    }
  }

  .card-actions {
    padding: 0 1rem 1rem 1rem;
    button {
      width: 100%;
    }
  }
}

.menu__settings__layout {
  display: flex;
  flex-direction: column;
}

.menu__event-date-heading__layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    position: relative;
    top: 0.5rem;
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .menu-card {
    margin-top: 0;
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .menu__settings__layout {
    flex-direction: row;
    justify-content: space-between;

    .menu__settings__left {
      flex-basis: 45%;
    }

    .menu__settings__right {
      flex-basis: 50%;
    }
  }

  .menu__header {
    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__btn-link {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      height: 100%;

      svg {
        font-size: 2.75rem;
        margin: 0 auto;
        margin-bottom: 7px;
      }

      span {
        border-top: 1px solid $color-border;
        padding-top: 7px;
      }
    }
  }
}

.events__modal__add__wrapper {
  margin: 1.5rem 2rem;
}
