.card-common {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  --tw-bg-opacity: 1;

  &__wrapper {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 24rem;
    background-color: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity));
    border: 1px solid rgb(245, 245, 245);
  }

  &__body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__title {
      font-weight: 500;
      font-size: 1rem;
      margin-top: 1rem;
    }

    &__subtitle {
      overflow: hidden !important;
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: 2 !important;
      margin-top: 0.5rem;
      color: #a696a2;
      min-height: 42px;
    }

    &__hr {
      border-top: 2px solid #e2e8f0;
      width: 3rem;
      height: 0.25rem;
      margin: 1.5rem 0;
    }

    &__children {
      color: #697383;
      svg {
        margin-right: 7px;
        color: #94a3b8;
        font-size: 0.9rem;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;

    &__hr {
      height: 0.125rem;
      position: relative;
      background: #eff2f7;
    }

    &__content {
      text-align: right;
      padding: 1rem 1.5rem;
      background-color: #f8fafc;

      .button-card {
        border-color: #e2e8f0;
        color: #333;
        border-radius: 1rem;

        &:hover {
          background: #e4e9ef;
          border-color: #ccd2da;
          color: #333;
        }

        svg {
          position: relative;
          top: 1px;
          margin-left: 5px;
        }

        &__delete {
          margin-left: 1rem;
          position: relative;
          width: 56.33px;

          svg {
            margin-left: 0;
          }

          .lds-ring {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 8px;
            left: 15px;

            div {
              width: 24px;
              height: 24px;
              margin: 0;
              border-width: 4px;
            }
          }
        }

        &__delete[disabled], &__delete:disabled {
          bottom: 5px;
        }
      }
    }
  }
}
