.event-delete-data-field {
    padding: 40px;

    .dialog-buttons-group {
        justify-content: center;
        text-align: center;
        display: flex;
        margin: 0 2rem;
        margin-top: 2rem;

        button {
            margin-right: 1rem;
            width: 100%;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}