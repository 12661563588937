.page {
  .header {
    background-color: #fff;
    border-bottom: 1px solid $color-border;
    padding: 1.25rem;
    min-height: 21px;
    max-height: 21px;

    #btn-mobile-nav {
      cursor: pointer;
    }
  }

  .body {
    padding: 2rem;

    .title {
      display: block;
      font-size: 3rem;
    }

    .sub-title {
      display: block;
      color: #666;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .page {
    margin-left: 260px;
    
    .header {
      padding: 1.25rem 5rem;

      #btn-mobile-nav {
        display: none;
      }
    }

    .body {
      padding: 2rem 5rem;
    }

    .body--split--2 {
      display: flex;
      flex-direction: row;
    }
  }
}
