.locations-list {
  .card-common__body__subtitle,
  .card-common__body__hr {
    display: none !important;
  }

  .card-common__wrapper {
    height: auto;
  }
}

.location-page {
  margin-left: 0;

  .location-body {
    max-width: 42rem;
  }

  .location-card-body {
    margin-top: 1rem;
    font-size: 0.9rem;
    
    span {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
