input[type=text], input[type=search], input[type=password], input[type=time], input[type=number], input[type=date], textarea, select {
  padding: 15px;
  border: 1px solid $color-border;
  border-radius: 5px;
  width: calc(100% - 30px);
}

.recommended-size-price {
  padding: 3px !important;
}

select {
  width: 100%;
}

.input-search-container {
  position: relative;
  margin: 0.5rem 0;

  input {
    padding: 15px 0 15px 35px;
  }

  svg {
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 24px;
    height: 24px;
    color: $color-primary;
  }
}

.switch-group {
  span {
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin: 0 10px;
    font-weight: bold;
    color: #666;
  }

  .is-active {
    color: $color-primary;
  }
}

.input-error {
  display: block;
  font-weight: 700;
  color: $color-warning;
}

.multi-select {
  input {
    margin: 5px 10px !important;
  }
}

.input-with-prefix-icon {
  span {
    margin-right: 5px;
  }

  input {
    width: 100px;
  }
}

.input-group-symbol-after {
  display: flex;
  border: 1px solid $color-border;
  border-radius: 5px;

  input {
    flex-basis: 90%;
    border: 0;
  }

  .symbol-wrapper {
    display: flex;
    flex-basis: 10%;
    background-color: $color-cream;
    font-weight: 600;
    color: $color-primary;
    justify-content: center;
  }

  span {
    font-size: 1.5rem;
    margin: auto 0;
  }
}

@media only screen and (min-width: $breakpoint-desktop-small) {
  .input--two {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      flex-basis: 45%;
      
      input {
        width: 85%;
      }
    }
  }
}
