.display-none {
  display: none;
}

.display--inline {
  display: inline;
}

.hide-for {
  &-mobile {
    @media only screen and (max-width: $breakpoint-tablet) {
      display: none;
    }
  }
}

.show-for {
  &-mobile-only {
    @media only screen and (min-width: $breakpoint-tablet) {
      display: none;
    }
  }
}

.float {
  &-right {
    float: right;
  }
}
