.income-stats-wrapper {
  display: block;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .stat-section {
    flex: 0 1 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;

    &:first-child {
      margin-left: 0;
      margin-top: 0rem;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0rem;
    }

    .icon-bg {
      background-color: #eee;
      width: 60px;
      height: 60px;
      border-radius: 5px;
      position: relative;

      svg {
        display: block;
        margin: 0 auto;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        font-size: 2rem;
        color: #ddd;
      }

      &--income {
        background-color: #def6ee;

        svg {
          color: #21c188;
        }
      }

      &--loss {
        background-color: #f6dede;

        svg {
          color: #ff6060;
        }
      }

      &--zero {
        background-color: #f4f6de;

        svg {
          color: #b1c121;
          font-size: 1.4rem;
        }
      }
    }

    .stats-wrapper {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .time-span,
      .income {
        display: block;
        font-weight: 600;
      }

      .time-span {
        color: rgb(141, 141, 141);

        &--skeleton {
          background-color: #ddd;
          height: 21px;
          width: 75px;
        }
      }

      .income {
        font-size: 1.5rem;

        &--skeleton {
          background-color: #ddd;
          height: 1.75rem;
          width: 100px;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-tablet) {
  .income-stats-wrapper {
    flex-direction: row;

    .stat-section {
      margin: 0;
      padding: 0;
      flex: 0 1 33%;
      border-bottom: none;
      border-right: 1px solid #ddd;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
