.event-add-data-field-dialog {
    padding: 40px;

    form {
        text-align: left;

        label {
            margin-bottom: 5px;
        }

        .group-switch {
            display: flex;
            justify-content: space-between;

            label {
                margin: auto 0;
            }
        }

        .input-error {
            font-size: 14px;
            margin-top: 5px;
        }

        .dialog-buttons-group {
            justify-content: center;
            text-align: center;
            display: flex;
            margin: 0 2rem;
            margin-top: 2rem;

            button {
                margin-right: 1rem;
                width: 100%;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}